.home-page-ecowrap-logo {
    max-width: 50%;
    width: 300px;

}

.home-page-ecowrap-logo img {
    width: 100%;
    object-fit: contain;
}

.properties-search-input {
    position: relative;
}

.suggestions-list {
    position: absolute;
    z-index: 100;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    background-color: white;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.greenDot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: 5px;
    background-color: green;
}